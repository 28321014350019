<template>
  <div id="showroom">
    <b-row class="mb-1">
      <!-- RUTA DE NAVEGACIÓN POR CATEGORÍAS -->
      <b-col>
        <b-breadcrumb>
          <b-breadcrumb-item
            v-for="(brc, index) in navItems"
            :key="brc.text + '_' + brc.id"
            :text="brc.text"
            :active="index === navItems.length - 1 ? true : false"
            @click="index !== navItems.length - 1 ? getElement(brc.id) : ''"
          />
        </b-breadcrumb>
      </b-col>
      <!-- BUTTON EDITAR CATEGORIA -->
      <b-col class="d-flex justify-content-end align-items-center">
        <div
          v-if="navItems.length > 1"
          class="mr-2"
        >
          <b-button
            v-b-tooltip.hover.top.v-primary="$t('contents.editCat')"
            variant="outline-primary"
            class="d-flex align-items-center"
            @click="routeToEditView(navItems[navItems.length - 1].id, 0)"
          >
            <feather-icon
              icon="EditIcon"
              size="18"
              class="mr-50 cursor-pointer"
            />
            {{ $t('Edit') }}
          </b-button>
        </div>
        <!-- BUTTON VOLVER -->
        <div v-if="navItems.length > 1">
          <b-button
            variant="danger"
            @click="getElement(navItems[navItems.length - 2].id)"
          >
            {{ $t("dataGeneric.goBack") }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="!manual && !isFinal"
        class="align-items-center d-flex"
      >
        <b-form-input
          id="search"
          v-model="searchInput"
          type="text"
          name="search"
          :disabled="isFinal"
          :placeholder="searchInputPlaceholder"
          class="searchProducts ml-1 mr-1"
          @keyup="onKeyUp($event)"
          @focus="searching()"
        />
        <b-button
          v-if="seeker"
          variant="info"
          @click.stop.prevent="onKeyUp($event,true)"
        >
          <feather-icon
            icon="SearchIcon"
            size="18"
          />
        </b-button>
        <b-col v-if="seeker">
          <b-form-checkbox
            v-model="searchById"
            class="mt-2"
            switch
            @change="onKeyUp($event,true)"
          >
            {{ $t('activeSearchById') }}
          </b-form-checkbox>
        </b-col>
        <b-button
          v-if="seeker"
          variant="danger"
          @click.stop.prevent="cleanSearch"
        >
          {{ $t('dataGeneric.cancel') }}
        </b-button>
      </b-col>
      <b-col v-if="!seeker">
        <b-row class="pr-1 justify-content-end">
          <div
            v-if="code"
            class="d-flex justify-content-end"
          >
            <feather-icon
              v-b-tooltip.hover.top.v-primary="orderType == 'MAN' ? $t('contents.sortManual') : $t('alfa')"
              icon="InfoIcon"
              class="mr-2"
              style="align-self: center;"
            />
          </div>
          <div class="justify-content-end">
            <b-dropdown right>
              <template #button-content>
                {{ orderList[orderType] }}
              </template>
              <b-dropdown-item @click="ordenar('manual')">
                {{ $t("contents.sortManual") }}
              </b-dropdown-item>
              <b-dropdown-item
                v-if="isFinal"
                @click="ordenar('alfa')"
              >
                {{ $t('alfa') }}
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <div
            v-if="!isFinal"
            class="ml-1 "
            style="text-align: end;"
          >
            <b-button
              variant="primary"
              @click="createCategory"
            >
              <feather-icon
                icon="FolderPlusIcon"
                class="mr-50"
              />
              <span class="text-nowrap">{{ $t("contents.addCategory") }}</span>
            </b-button>
          </div>
          <b-button
            v-else
            variant="primary"
            class="text-nowrap ml-1"
            @click="createProduct"
          >
            {{ $t("addProd") }}
          </b-button>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="isFinal && userData.isSuperuser && isDevelopment">
      <b-col
        style="text-align: end;"
        class="mt-1"
      >
        <b-button
          variant="warning"
          size="sm"
          @click.stop.prevent="$refs['superuser'].show()"
        >
          Duplicar productos - DEMOS
        </b-button>
      </b-col>
    </b-row>
    <!-- NÚMERO DE ELEMENTOS -->
    <b-row>
      <b-col
        v-if="manual"
        class="d-flex align-items-center"
      >
        <b-button
          variant="outline-warning"
          disabled
        >
          {{ $t("contents.manualSortMode") }}
        </b-button>
        <b-button
          class="ml-2"
          variant="success"
          @click="ordenar('manual')"
        >
          {{ $t('contents.finalize') }}
        </b-button>
      </b-col>
      <b-col
        v-if="!seeker"
        class="d-flex justify-content-end mt-2"
      >
        <span>
          <strong>{{ isFinal ? products.length : category.length }}</strong> {{ $t('contents.elements') }}
        </span>
      </b-col>
    </b-row>
    <b-overlay v-if="seeker">
      <searchProducts
        ref="searchProd"
        class="mt-2"
        :search-by-id="searchById"
        :search-input="search"
      />
    </b-overlay>
    <b-overlay
      v-else
      variant="transparent"
      :show="overlay"
    >
      <b-tabs
        class="mt-2"
        pills
        lazy
      >
        <b-tab>
          <template #title>
            <feather-icon icon="GridIcon" />
            <span>{{ $t('category.card') }}</span>
          </template>
          <!-- CATEGORÍAS -->
          <div v-if="!isFinal">
            <draggable
              v-if="manual"
              :list="category"
              class="row manualOrder rounded"
              group="categories"
              :sort="true"
              @choose="chooseCategory"
            >
              <b-col
                v-for="categorie in category"
                :id="categorie.node.id + ' ' + categorie.node.order"
                :key="categorie.node.id"
                class="mt-2 hang-on-hover"
                md="6"
                lg="3"
                xl="3"
                sm="12"
                @dragend="updateOrder($event)"
              >
                <b-card
                  class="cardContent mb-0"
                  no-body
                >
                  <b-img
                    class="card-img-top cursor-move w-100"
                    :alt="`${categorie.node.name}-${categorie.node.id}`"
                    fluid-grow
                    :src="getImage(categorie)"
                    @error="errorImg"
                  />
                  <div
                    id="acortarPadre"
                    class="m-1"
                  >
                    <div class="d-flex justify-content-between">
                      <b-card-title
                        id="acortar"
                        class="mb-25 d-flex justify-content-between item-name "
                      >
                        <h5 class="align-items-center">
                          <span
                            :class="categorie.node.isActive ? 'mr-50 bullet bullet-success bullet-sm' : 'mr-50 bullet bullet-danger bullet-sm'"
                          />
                          {{ categorie.node.name }}
                        </h5>
                      </b-card-title>
                    </div>
                    <b-card-text
                      id="acortar"
                      class="text-muted"
                    >
                      {{ catType[categorie.node.type] }} {{ $t('contents.category') }}
                    </b-card-text>
                  </div>
                </b-card>
              </b-col>
            </draggable>
            <b-row v-else>
              <b-col
                v-for="categorie in category"
                :key="categorie.node.id"
                class="mt-2"
                md="6"
                lg="3"
                xl="3"
                sm="12"
              >
                <b-card
                  class="cardContent mb-0"
                  no-body
                >
                  <b-img
                    class="card-img-top cursor-pointer w-100"
                    :alt="`${categorie.node.name}-${categorie.node.id}`"
                    fluid-grow
                    :src="getImage(categorie)"
                    @click="getElement(categorie.node.id)"
                    @error="errorImg"
                  />
                  <div
                    id="acortarPadre"
                    class="m-1"
                  >
                    <div class="d-flex justify-content-between">
                      <b-card-title
                        id="acortar"
                        class="mb-25 d-flex justify-content-between item-name "
                      >
                        <h5 class="align-items-center">
                          <span
                            :class="categorie.node.isActive ? 'mr-50 bullet bullet-success bullet-sm' : 'mr-50 bullet bullet-danger bullet-sm'"
                          />
                          {{ categorie.node.name }}
                        </h5>
                      </b-card-title>
                    </div>
                    <b-card-text
                      id="acortar"
                      class="text-muted"
                    >
                      {{ $t('contents.category') }} {{ catType[categorie.node.type] }}
                    </b-card-text>
                  </div>
                  <b-card-body>
                    <div class="actions">
                      <div class="action">
                        <feather-icon
                          class="cursor-pointer"
                          icon="Edit2Icon"
                          size="20"
                          @click="routeToEditView(categorie.node.id, 0)"
                        />
                        <b-dropdown
                          variant="link"
                          toggle-class="text-decoration-none"
                          no-caret
                          right
                        >
                          <template #button-content>
                            <feather-icon
                              color="white"
                              icon="MoreVerticalIcon"
                              size="20"
                            />
                          </template>

                          <b-dropdown-item
                            v-if="goToInit(categorie)"
                            @click="updateParent(categorie)"
                          >
                            <feather-icon
                              icon="ArrowUpIcon"
                              class="mr-50"
                              size="18"
                            />
                            {{ $t("contents.moveToInit") }}
                          </b-dropdown-item>

                          <b-dropdown-item @click="showModal(categorie.node)">
                            <feather-icon
                              icon="CornerRightUpIcon"
                              class="mr-50"
                              size="18"
                            />
                            {{ $t("contents.moveToCat") }}
                          </b-dropdown-item>

                          <b-dropdown-item @click="deleteFolder(categorie.node.id, 1)">
                            <feather-icon
                              icon="DeleteIcon"
                              size="18"
                              class="mr-50"
                            />
                            {{ $t("contents.dropCat") }}
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </div>
          <!-- PRODUCTOS -->
          <div v-else>
            <draggable
              v-if="manual"
              :list="products"
              class="row manualOrder rounded"
              group="products"
              :sort="true"
            >
              <!-- @choose="chooseCategory" -->
              <b-col
                v-for="product in products"
                :id="product.node.id + ' ' + product.node.order"
                :key="product.node.id"
                class="mt-2 hang-on-hover"
                md="6"
                lg="3"
                xl="3"
                sm="12"
                @dragend="updateOrder"
              >
                <b-card
                  class="cardContent mb-0"
                  no-body
                >
                  <b-img
                    class="card-img-top cursor-move w-100"
                    :alt="`${product.node.name}-${product.node.id}`"
                    fluid-grow
                    :src="getImage(product)"
                    @error="errorImg"
                  />
                  <div
                    id="acortarPadre"
                    class="m-1"
                  >
                    <div class="d-flex justify-content-between">
                      <b-card-title
                        id="acortar"
                        class="mb-0 d-flex justify-content-between"
                      >
                        <h5 class="item-name">
                          <span
                            v-if="product.node.state == 'GRE'"
                            class="mr-50 bullet bullet-success bullet-sm"
                          />
                          <span
                            v-if="product.node.state == 'YEW'"
                            class="mr-50 bullet bullet-warning bullet-sm"
                          />
                          <span
                            v-if="product.node.state == 'RED'"
                            class="mr-50 bullet bullet-danger bullet-sm"
                          />
                          <span
                            v-if="product.node.state == 'BCK'"
                            class="mr-50 bullet bullet-dark bullet-sm"
                          />
                          {{
                            product.node.name
                          }}
                        </h5>
                      </b-card-title>
                    </div>
                    <b-card-text
                      id="acortar"
                      class="text-muted  align-items-center"
                    >
                      {{ $t('producto') }}
                    </b-card-text>
                  </div>
                </b-card>
              </b-col>
            </draggable>
            <b-row v-else>
              <b-col
                v-for="product in products"
                :key="product.node.id"
                class="mt-2"
                md="6"
                lg="3"
                xl="3"
                sm="12"
              >
                <b-card
                  class="cardContent"
                  no-body
                >
                  <b-img
                    class="card-img-top cursor-pointer w-100"
                    :alt="`${product.node.name}-${product.node.id}`"
                    fluid-grow
                    :src="getImage(product)"
                    @click="routeToEditView(product.node.id, 1)"
                    @error="errorImg"
                  />
                  <div
                    id="acortarPadre"
                    class="mt-1 ml-1"
                  >
                    <div class="d-flex justify-content-between">
                      <b-card-title
                        id="acortar"
                        class="mb-0 d-flex justify-content-between"
                      >
                        <h5 class="item-name">
                          <span
                            v-if="product.node.state == 'GRE'"
                            class="mr-50 bullet bullet-success bullet-sm"
                          />

                          <span
                            v-if="product.node.state == 'YEW'"
                            class="mr-50 bullet bullet-warning bullet-sm"
                          />
                          <span
                            v-if="product.node.state == 'RED'"
                            class="mr-50 bullet bullet-danger bullet-sm"
                          />
                          <span
                            v-if="product.node.state == 'BCK'"
                            class="mr-50 bullet bullet-dark bullet-sm"
                          />
                          {{
                            product.node.name
                          }}
                        </h5>
                      </b-card-title>
                    </div>
                    <b-card-text
                      id="acortar"
                      class="text-muted  align-items-center"
                    >
                      {{ $t('producto') }}
                    </b-card-text>
                  </div>
                  <b-card-body>
                    <div class="actions">
                      <div class="action">
                        <b-dropdown
                          variant="link"
                          toggle-class="text-decoration-none"
                          no-caret
                          right
                        >
                          <template #button-content>
                            <feather-icon
                              color="white"
                              icon="MoreVerticalIcon"
                              size="20"
                            />
                          </template>
                          <b-dropdown-item @click.stop.prevent="routeToEditView(product.node.id)">
                            <feather-icon
                              class="cursor-pointer"
                              icon="Edit2Icon"
                              size="18"
                            />
                            {{ $t("Edit") }}
                          </b-dropdown-item>
                          <b-dropdown-item @click="productShowModal(product.node.id)">
                            <feather-icon
                              icon="CornerRightUpIcon"
                              class="mr-50"
                              size="18"
                            />
                            {{ $t("contents.moveContent") }}
                          </b-dropdown-item>
                          <b-dropdown-item @click.stop.prevent="dropProductByCategory(product.node.id)">
                            <feather-icon
                              class="mr-50"
                              icon="DeleteIcon"
                              size="18"
                            />
                            {{ $t('quitProd') }}
                          </b-dropdown-item>
                          <b-dropdown-item @click="deleteFolder(product.node.id, 0)">
                            <feather-icon
                              icon="Trash2Icon"
                              class="mr-50"
                              size="18"
                            />
                            {{ $t('dataGeneric.delete') }}
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="ListIcon" />
            <span>{{ $t('List') }}</span>
          </template>
          <!-- CATEGORÍAS -->
          <div v-if="!isFinal">
            <b-table-simple
              ref="catList"
              show-empty
              hover
              selectable
              :select-mode="'single'"
              responsive
              primary-key="id"
              :sticky-header="'800px'"
              :no-border-collapse="true"
            >
              <b-thead>
                <b-tr>
                  <b-th
                    v-for="i in tableColumns"
                    :key="i.key"
                  >
                    {{ i.label }}
                  </b-th>
                </b-tr>
              </b-thead>
              <draggable
                :v-model="category"
                tag="tbody"
                handle=".handle"
                :list="category"
                group="categories"
                :sort="true"
              >
                <b-tr
                  v-for="item in category"
                  :key="item.node.id"
                  @dragend="updateOrderTable"
                >
                  <b-td @click="!manual ? onRowSelected(item) : ''">
                    <div
                      class="d-flex"
                      style="align-items: center"
                    >
                      <b-img
                        id="avatar"
                        class="mr-2 ml-2"
                        rounded="lg"
                        :src="getImage(item)"
                        @error="errorImg"
                      />
                      <p>{{ item.node.name }}</p>
                    </div>
                  </b-td>
                  <b-td @click="!manual ? onRowSelected(item) : ''">
                    {{ item.node.isFinal ? 'Final' : $t('noFinal') }}
                  </b-td>
                  <b-td>
                    <i
                      v-if="manual"
                      class="fa fa-align-justify handle cursor-move text-warning"
                    />
                    <b-dropdown
                      v-else
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                      right
                    >
                      <template #button-content>
                        <feather-icon
                          color="white"
                          icon="MoreVerticalIcon"
                          size="20"
                        />
                      </template>
                      <div>
                        <b-dropdown-item @click="routeToEditView(item.node.id, 0)">
                          <feather-icon
                            icon="Edit2Icon"
                            size="20"
                          />
                          {{ $t("contents.editCat") }}
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteFolder(item.node.id, 1)">
                          <feather-icon
                            icon="DeleteIcon"
                            size="18"
                          />
                          {{ $t("contents.dropCat") }}
                        </b-dropdown-item>
                      </div>
                    </b-dropdown>
                  </b-td>
                </b-tr>
              </draggable>
            </b-table-simple>
          </div>
          <!-- PRODUCTOS -->
          <div v-else>
            <b-table-simple
              ref="prodList"
              show-empty
              hover
              selectable
              :select-mode="'single'"
              responsive
              primary-key="id"
              :sticky-header="'800px'"
              :no-border-collapse="true"
            >
              <b-thead>
                <b-tr>
                  <b-th
                    v-for="i in tableColumnsP"
                    :key="i.key"
                  >
                    {{ i.label }}
                  </b-th>
                </b-tr>
              </b-thead>
              <draggable
                :v-model="products"
                tag="tbody"
                handle=".handle"
                :list="products"
                group="products"
                :sort="true"
              >
                <b-tr
                  v-for="item in products"
                  :key="item.node.id"
                  @dragend="updateOrderTable"
                >
                  <b-td @click="!manual ? routeToEditView(item.node.id, 1) : ''">
                    <div
                      class="d-flex"
                      style="align-items: center"
                    >
                      <b-img
                        id="avatar"
                        class="mr-2 ml-2"
                        rounded="lg"
                        :src="getImage(item)"
                        @error="errorImg"
                      />
                      <p>{{ item.node.name }}</p>
                    </div>
                  </b-td>
                  <b-td>
                    <span
                      v-if="item.node.state == 'GRE'"
                      class="text-success"
                    >{{ $t('ecommerce.public') }}</span>
                    <span
                      v-if="item.node.state == 'YEW'"
                      class="text-warning"
                    >{{ $t('dataGeneric.internal') }}</span>
                    <span
                      v-if="item.node.state == 'RED'"
                      class="text-danger"
                    >{{ $t('ecommerce.NoPublic') }}</span>
                    <span
                      v-if="item.node.state == 'BCK'"
                      class="text-danger"
                    >{{ $t('ecommerce.NoPublic') }}</span>
                  </b-td>
                  <b-td>{{ fechaFormato(item.node.modifiedAt) }}</b-td>
                  <b-td>
                    <i
                      v-if="manual"
                      class="fa fa-align-justify handle cursor-move text-warning"
                    />
                    <b-dropdown
                      v-else
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                      right
                    >
                      <template #button-content>
                        <feather-icon
                          color="white"
                          icon="MoreVerticalIcon"
                          size="20"
                        />
                      </template>
                      <div>
                        <b-dropdown-item @click="deleteFolder(item.node.id, 0)">
                          <feather-icon
                            icon="DeleteIcon"
                            size="18"
                          />
                          {{ $t('dataGeneric.delete') }}
                        </b-dropdown-item>
                      </div>
                    </b-dropdown>
                  </b-td>
                </b-tr>
              </draggable>
            </b-table-simple>
          </div>
        </b-tab>
      </b-tabs>
    </b-overlay>
    <b-modal
      id="superuser"
      ref="superuser"
      hide-footer
      size="lg"
      :title="$t('selectPlatf')"
    >
      <div class="d-block text-center">
        <platform-selector @client="getPlatform" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs['superuser'].hide()"
      >
        {{ $t("dataGeneric.close")
        }}
      </b-button>
    </b-modal>
    <!-- MODAL PARA SELECCIONAR CONTENIDOS DE PLATAFORMAS -->
    <b-modal
      id="product-platform"
      ref="product-platform"
      hide-footer
      size="xl"
      :title="$t('selcPlat')"
    >
      <div class="d-block text-center">
        <product-platform-selector
          :platform="platformId"
          @data="SelectProductId"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs['product-platform'].hide()"
      >
        {{ $t("dataGeneric.close")
        }}
      </b-button>
    </b-modal>
    <b-modal
      ref="modal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <category-product-selector
          v-if="!isFinal"
          :is-final="false"
          :columns="categorySelectorTable"
          :id-not="choosedCategory"
          @confirm-selection="updateParentCategory"
        />
        <category-product-selector
          v-if="isFinal"
          :is-final="true"
          :columns="categorySelectorTable"
          :id-not="code"
          @confirm-selection="updateParentProduct"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </div>
</template>
<script>
import {
  BTableSimple,
  BTd,
  BTr,
  BThead,
  BTh,
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BCard,
  BButton,
  BDropdown,
  BDropdownItem,
  BImg,
  BCardBody,
  BCardText,
  BCardTitle,
  BTabs,
  BTab,
  VBTooltip,
  BOverlay,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'
import PlatformSelector from '@/views/common/PlatformSelector.vue'
import ProductPlatformSelector from '@/views/common/ProductPlatformSelector.vue'
import draggable from 'vuedraggable'
import { getUserData } from '@/auth/utils'
import searchProducts from '@/views/contents/search/searchProd.vue'
import CategoryProductSelector from '../../common/CategoryProductSelector.vue'
import '@core/assets/fonts/icons/css/all.css'
import '@core/assets/fonts/icons/scss/fontawesome.scss'

const fileError = require('@/assets/images/backend/file-error.png')
const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BTableSimple,
    BTd,
    BTr,
    PlatformSelector,
    ProductPlatformSelector,
    BDropdown,
    BThead,
    BTh,
    BTabs,
    BTab,
    BDropdownItem,
    BCardText,
    BBreadcrumb,
    BBreadcrumbItem,
    draggable,
    BImg,
    BCardBody,
    BCardTitle,
    BOverlay,
    CategoryProductSelector,
    BFormInput,
    BFormCheckbox,
    searchProducts,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,

  },
  props: {
    code: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      type: null,
      childCategory: [],
      category: [],
      products: [],
      manual: false,
      choosedCategory: null,
      choosedProduct: null,
      orderType: 'MAN',
      isDevelopment: false,
      catType: {
        NOR: 'normal',
        VEN: this.$t('Vendedor'),
      },
      tableColumns: [
        { key: 'name', label: this.$t('firstName') },
        { key: 'is_final', label: this.$t('catFinal') },
        { key: 'actions', label: this.$t('resources.tableHeader.actions') },
      ],
      categorySelectorTable: [
        { key: 'image', label: this.$t('contents.category') },
        { key: 'name', label: this.$t('firstName') },
        { key: 'id', label: 'ID' },
      ],
      tableColumnsP: [
        { key: 'name', label: this.$t('firstName') },
        { key: 'state', label: this.$t('editContent.status') },
        { key: 'modifiedAt', label: this.$t('Modificación') },
        { key: 'actions', label: this.$t('resources.tableHeader.actions') },
      ],
      isFinal: false,
      overlay: false,
      userData: getUserData(),
      platformId: '',
      orderList: {
        MAN: 'Manual',
        EAS: this.$t('asct'),
        ALF: this.$t('alf'),
        EDE: this.$t('desct'),
      },
      brItems: [
        {
          id: null,
          text: this.$t('contents.start'),
        },
      ],
      navItems: [
        {
          id: null,
          text: this.$t('contents.start'),
        },
      ],
      seeker: false,
      searchInput: '',
      search: '',
      searchInputPlaceholder: this.$t('Products'),
      searchById: false,
    }
  },
  mounted() {
    const mode = process.env.VUE_APP_MODE ? process.env.VUE_APP_MODE : process.env.NODE_ENV
    if (mode !== 'production') {
      this.isDevelopment = true
    } else {
      this.isDevelopment = false
    }
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }
    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    if (this.code) this.getCategory(this.code)
    else this.fetchData()
  },
  methods: {
    goToInit(item) {
      if (item.node.parent === null) {
        return false
      }

      return true
    },
    getPlatform(data) {
      this.platformId = data.id
      this.$refs.superuser.hide()
      this.$refs['product-platform'].show()
    },
    /* TODO try */
    updateParent(categorie) {
      this.hideModal()

      axios
        .post('', {
          query: `
          mutation{
            updateCategoryShop(id:"${categorie.node.id}",input:{ parent: ""}) {
                shop{
                  id
                  name
                  parent{
                    id
                    name
                  }
                }
              }
            }
        `,
        }).then(result => {
          messageError(result, this)

          if (this.code) {
            this.getCategory(this.code)
          } else {
            this.fetchData()
          }
        }).catch(() => {

        })
    },
    updateParentCategory(item) {
      this.hideModal()
      axios
        .post('', {
          query: `
            mutation{
              updateCategoryShop(id: "${this.choosedCategory}",input:{ parent: "${item.node.id}"}) {
                shop{
                  id
                  name
                  parent{
                    id
                    name
                  }
                }
              }
            }
          `,
        }).then(result => {
          messageError(result, this)

          if (this.code) {
            this.getCategory(this.code)
          } else {
            this.fetchData()
          }
        }).catch(() => {

        })
    },
    updateParentProduct(item) {
      this.hideModal()
      axios
        .post('', {
          query: `
            mutation{
              updateProductsShop(id: "${this.choosedProduct}", input:{ categories:[${item.node.id}]}){
                product{
                  name
                }
              }
            }
          `,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)
          if (this.code) {
            this.getCategory(this.code)
          } else {
            this.fetchData()
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    productShowModal(id) {
      this.choosedProduct = id
      this.$refs.modal.show()
    },
    /* METODO PARA REALIZAR LAS LLAMADAS DE LOS DISTINTOS MODALES SEGÚN SE NECESITE */
    showModal(node) {
      this.choosedCategory = node.id
      this.$refs.modal.show()
    },
    hideModal() {
      this.$refs.modal.hide()
    },
    fechaFormato(value) {
      const date = new Date(value)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }

      return value != null
        ? new Intl.DateTimeFormat('default', options).format(date)
        : value
    },
    fetchData() {
      this.overlay = true
      const { headers } = this

      axios
        .post('', {
          query: `   query{
                allCategoryShop(client: "${this.userData.profile.client.id}",parent_Isnull:true,orderBy:"order") {
                edges {
                    node {
                    id
                    name    
                    order
                    type
                    isFinal
                    isActive                
                    imageUrl
                    description
                    parent {
                        id
                        name                    
                        isFinal
                         }                       
                    }
                }
                }
                } `,
        }, { headers })
        .then(response => {
          messageError(response, this)

          this.category = response.data.data.allCategoryShop.edges
          this.overlay = false
        })
        .catch(err => (console.log(err)))
    },
    getCategory(id) {
      this.overlay = true
      const { headers } = this
      axios
        .post('', {
          query: `   query{
                allCategoryShop(id:"${id}",client: "${this.userData.profile.client.id}",orderBy:"order") {
                totalCount
                edges {
                    node {
                    id
                    name
                    isFinal                    
                    imageUrl
                    description   
                    type
                    order                 
                    orderType                 
                    parent {
                        id
                        name                    
                        isFinal
                         }   
                    categoryProduct(orderBy:"order") {
                        edges {
                            node {
                            id            
                            name
                            order
                            imageUrl
                            description
                            state
                            modifiedAt
                            }
                        }
                    }
                    childCategories(orderBy:"order") {
                    totalCount
                        edgeCount                            
                    edges {
                        node {
                        id                              
                        name
                        type
                        order                        
                        image
                        imageUrl
                        isFinal                        
                        isActive                                                
                        }
                      }
                    }
                  }
              }
            }
        } `,
        }, { headers })
        .then(response => {
          messageError(response, this)

          const res = response.data.data.allCategoryShop.edges[0].node
          this.brItems = [
            {
              id: null,
              text: this.$t('contents.start'),
            },
          ]
          this.brItems.push({
            text: res.name,
            id: res.id,
          })

          this.isFinal = res.isFinal
          this.orderType = res.orderType
          if (!this.isFinal) this.category = res.childCategories.edges
          else this.products = res.categoryProduct.edges

          if (res.parent != null) this.recursive(id)
          else { this.navItems = this.brItems.slice() }

          this.$emit('final', this.isFinal)
          this.overlay = false
        })
        .catch(err => {
          console.log(err)
          this.overlay = false
        })
    },
    recursive(id) {
      axios
        .post('', {
          query: `
                query {
                allCategoryShop(id:"${id}",client:"${this.userData.profile.client.id}")  {
                    totalCount
                      edges {
                        node {
                          id
                          name                             
                          parent {
                            id
                            name 
                            isFinal
                          }         
                                
                        }
                    }
                }
            }                        
          `,
        })
        .then(result => {
          messageError(result, this)
          if (result.data.data.allCategoryShop.edges[0].node.parent != null) {
            const i = result.data.data.allCategoryShop.edges[0].node.parent.id
            const n = result.data.data.allCategoryShop.edges[0].node.parent.name

            this.brItems.splice(1, 0, {
              text: n,
              id: i,
            })
            this.recursive(i)
          } else {
            this.navItems = this.brItems.slice()
          }
        })
        .catch(() => { })
    },
    errorImg(e) {
      e.target.src = fileError
    },
    getElement(id = null) {
      if (id != null) {
        this.$router.push({ name: 'subCategory-shop', params: { code: id } })
      } else {
        this.$router.push({ name: 'category-shop' })
      }
    },
    getImage(data) {
      return data.node.imageUrl == null || data.node.imageUrl.length === 0
        ? noCover
        : data.node.imageUrl
    },
    routeToEditView(id, type) {
      if (type === 0) this.$router.push({ name: 'edit-category-shop', params: { id } })
      else { this.$router.push({ name: 'edit-products', params: { id } }) }
    },
    onRowSelected(items) {
      if (items.length !== 0) {
        this.getElement(items.node.id)
      }
    },
    chooseCategory(event) {
      this.choosedCategory = this.category[event.oldIndex]
    },
    /* LLAMADA DE FUNCIONES DE ORDENACIÓN SEGÚN EL TIPO DE ORDENACIÓN SELECCIONADA */
    ordenar(type) {
      switch (type) {
        case 'alfa':
          this.orderType = 'ALF'
          this.updateByAlpha()
          break
        case 'manual':
          this.orderType = 'MAN'
          this.manual = !this.manual
          break
        default:
          break
      }
    },
    updateOrder(event) {
      this.overlay = true
      const arrayNum = []
      const arrayId = []
      for (let i = 0; i < event.target.parentNode.childNodes.length; i += 1) {
        arrayId.push(event.target.parentNode.childNodes[i].id.split(' ')[0])
        arrayNum.push(event.target.parentNode.childNodes[i].id.split(' ')[1])
      }

      arrayNum.sort((a, b) => a - b)

      const dataArr = new Set(arrayNum)

      const result = [...dataArr]
      while (result.length !== arrayNum.length) {
        const n = parseInt(result[result.length - 1], 10)
        result.push((n + 1).toString())
      }
      let query = ` mutation
                {`
      if (!this.isFinal) {
        for (let i = 0; i < result.length; i += 1) {
          query += `
                    m${i + 1}: updateCategoryShop(id:"${arrayId[i]}",input:{ order: ${result[i]
}}){
                                shop{
                                id
                                name
                                order
                                }
                            }
                            `
        }
      } else {
        for (let i = 0; i < result.length; i += 1) {
          query += `
                    m${i + 1}: updateProductsShop(id:"${arrayId[i]}",input:{order: ${result[i]
}}){
                            product{
                                id
                                name
                                order
                                }
                            }
                            `
        }
      }

      query += `
                }`

      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)
          this.changeTypeOrder()
          this.overlay = false
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 0, this)
          this.overlay = false
        })
    },
    updateByAlpha() {
      this.overlay = true
      const arrayNum = []
      let arrayId = []

      this.products.forEach(element => {
        arrayNum.push(element.node.order)
        arrayId.push({ id: element.node.id, name: element.node.name })
      })
      arrayId = arrayId.slice().sort((a, b) => a.name.localeCompare(b.name))
      const result = ['0']

      while (result.length !== arrayNum.length) {
        const n = parseInt(result[result.length - 1], 10)
        result.push((n + 1).toString())
      }
      let query = ` mutation
                {`

      for (let i = 0; i < result.length; i += 1) {
        query += `
                    m${i + 1}: updateProductsShop(id:"${arrayId[i].id}",input:{order: ${result[i]
}}){
                            product{
                                id
                                name
                                order
                                }
                            }
                            `
      }
      query += `
                }`

      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)
          this.changeTypeOrder()
          this.overlay = false
          this.brItems = [
            {
              id: null,
              text: this.$t('contents.start'),
            },
          ]
          this.navItems = [
            {
              id: null,
              text: this.$t('contents.start'),
            },
          ]
          this.getCategory(this.code)
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 0, this)
          this.overlay = false
        })
    },
    changeTypeOrder() {
      try {
        if (this.code) {
          axios
            .post('', {
              query: `
            mutation{
            updateCategoryShop(id:"${this.code}",input:{orderType:"${this.orderType}"})  {
              shop {
                id
              }
            }
            }
           `,
            }).then(result => {
              messageError(result, this)
            }).catch(() => {

            })
        }
        // eslint-disable-next-line no-empty
      } catch (error) {

      }
    },
    updateOrderTable() {
      this.overlay = true
      const arrayNum = []
      const arrayId = []

      const data = this.isFinal ? this.products : this.category
      for (let i = 0; i < data.length; i += 1) {
        arrayId.push(data[i].node.id)
        arrayNum.push(data[i].node.order)
      }
      arrayNum.sort((a, b) => a - b)
      const dataArr = new Set(arrayNum)

      const result = [...dataArr]
      while (result.length !== arrayNum.length) {
        const n = parseInt(result[result.length - 1], 10)
        result.push((n + 1).toString())
      }
      let query = ` mutation
      {`
      for (let i = 0; i < result.length; i += 1) {
        if (!this.isFinal) {
          query += `                    
                    m${i + 1}: updateCategoryShop(id:"${arrayId[i]}",input:{ order: ${result[i]
}}){
                            shop{
                          id
                          order
                          }
                        }
            `
        } else {
          query += `
                    m${i + 1}: updateProductsShop(id:"${arrayId[i]}",input:{order: ${result[i]
}}){
                        product{
                        id
                        name
                        order
                      }
                    }
                  `
        }
      }
      query += `
      }`
      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)
          this.changeTypeOrder()
          this.overlay = false
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 0, this)
          this.overlay = false
        })
    },
    createCategory() {
      try {
        if (this.code) {
          this.$router.push({ name: 'subCategory-shop-create', params: { id: this.code, order: this.category[this.category.length - 1].node.order + 1 } })
        } else {
          this.$router.push({ name: 'category-shop-create', params: { order: this.category[this.category.length - 1].node.order + 1 } })
        }
      } catch (error) {
        const length = this.category != null ? this.category.length : 0
        if (this.code) {
          this.$router.push({ name: 'subCategory-shop-create', params: { id: this.code, order: length } })
        } else {
          this.$router.push({ name: 'category-shop-create', params: { order: length } })
        }
      }
    },
    createProduct() {
      this.$router.push({ name: 'add-product', params: { id: this.code } })
    },
    dropProductByCategory(idToRemove) {
      const totalProduct = this.products.slice()
      console.log(totalProduct)
      const productToSave = totalProduct.filter(objeto => objeto.node.id !== idToRemove)
      console.log(productToSave)
      this.$swal({
        title: this.$t('removeFromCat'),
        text: this.$t('ifremProd'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yesRemove'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            axios
              .post('', {
                query: `
          mutation{
            updateCategoryShop(id:"${this.code}",input:{categoryProduct:[${this.getIdArray(productToSave)}]}) {
                shop{
                  id
                  name
                  parent{
                    id
                    name
                  }
                }
              }
            }
        `,
              }).then(res => {
                messageError(res, this)
                showToast(this.$t('success'), 1, this)
                if (this.code) {
                  this.getCategory(this.code)
                } else {
                  this.fetchData()
                }
              }).catch(() => {
                showToast(this.$t('error'), 0, this)
              })
          }
        })
    },
    getIdArray(array) {
      const res = []
      for (let i = 0; i < array.length; i += 1) {
        res.push(array[i].node.id)
      }
      return res
    },
    SelectProductId(data) {
      /* Mutation para añadir contenidos de otra categoría */
      const arrayProducts = []
      /* Añadir nuevo contenido al principio de la categoría y su orden 0 */
      this.products.forEach(element => {
        arrayProducts.push(element.node.id)
      })
      arrayProducts.unshift(...data)
      const query = `      mutation($id: ID!,$products: [ID]){
        updateCategoryShop(id: $id,input:{categoryProduct:$products}){                
          shop{
           id
           name
         }
       }
     }`
      axios
        .post('', {
          variables: {
            id: this.code,
            products: arrayProducts,
          },
          query,
        }).then(result => {
          messageError(result, this)
          showToast(this.$t('success'), 1, this)
          this.brItems = [
            {
              id: null,
              text: this.$t('contents.start'),
            },
          ]
          this.navItems = [
            {
              id: null,
              text: this.$t('contents.start'),
            },
          ]
          this.getCategory(this.code)
        }).catch(() => {
          showToast(this.$t('error'), 2, this)
        })
      this.$refs['product-platform'].hide()
    },
    /* MÉTODO PARA ELIMINAR CATEGORÍAS O PRODUCTOS */
    deleteFolder(id, type) {
      this.overlay = true
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          let query = ''
          if (type === 1) {
            query = `
                mutation{
                  deleteCategoryShop(id:"${id}") {
                    found
                    deletedId
                  }
                }`
          } else {
            query = `
                mutation{
                  deleteProductsShop(id:"${id}") {
                    found
                    deletedId
                  }
                }`
          }
          axios
            .post('', {
              query,
            })
            .then(res => {
              messageError(res, this)

              if (type === 1) {
                this.category = this.category.filter(item => item.node.id !== id)
              } else {
                this.products = this.products.filter(item => item.node.id !== id)
              }
              showToast(this.$t('category.removeCat'), 1, this)
              if (this.code) this.getCategory(this.code)
              else this.fetchData()
              this.overlay = false
            })
            .catch(res => {
              this.overlay = false
              showToast(this.$t('error'), 2, this)
              console.log(res)
            })
        } else {
          this.overlay = false
        }
      })
    },
    onKeyUp(event, click = false) {
      const keycode = event.keyCode || event.which
      if (keycode === 13 || click) {
        this.search = this.searchInput
      }
    },
    searching() {
      document.getElementById('search').classList.add('searching')
      this.searchInputPlaceholder = this.$t('searchProduct')
      this.seeker = true
    },
    cleanSearch() {
      document.getElementById('search').classList.remove('searching')
      this.type = null
      this.isFinal = null
      this.seeker = false
      this.search = ''
      this.searchInput = ''
      this.searchById = false
      this.searchInputPlaceholder = this.$t('Products')
    },
  },
}
</script>

<style lang="scss" scoped>

#showroom .searchProducts {
  width: 140px;
  box-sizing: border-box;
  font-size: 16px;
  background-image: url('~@/assets/images/icons/searchIcon.png');
  background-position: 10px 8px;
  background-repeat: no-repeat;
  padding: 10px 10px 10px 40px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}

#showroom .searchProducts:active {
  cursor: pointer;
}

#showroom .searching {
  width: 100%;

}

#showroom .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#showroom .cardContent img {
  height: 11vw;
  object-fit: cover;

}

#showroom .card-body {
  padding: 1rem;
}

#showroom .card-body h4 {
  font-size: 1rem !important;
}

#showroom .card-header {
  padding: 1rem;
}

#showroom .card-header h4 {
  font-size: 1rem !important;
}

#showroom .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  justify-content: flex-end;
}

#showroom .dropContainer {
  border: 3px dashed;
}

#showroom .list-group-item {
  transition: all 1s;
}

#showroom #acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#showroom #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

.manualOrder {
  border: 5px dashed #e69d5d;
  background: repeating-linear-gradient(-55deg,
      #283046,
      #283046 10px,
      #161d31 10px,
      #161d31 20px);
}

@-webkit-keyframes hang-on-hover {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }

  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@keyframes hang-on-hover {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }

  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@-webkit-keyframes hang-on-hover-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@keyframes hang-on-hover-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

.hang-on-hover {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hang-on-hover:hover,
.hang-on-hover:focus,
.hang-on-hover:active {
  -webkit-animation-name: hang-on-hover-sink, hang-on-hover;
  animation-name: hang-on-hover-sink, hang-on-hover;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}
</style>
