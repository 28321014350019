<template>
  <section>
    <b-overlay
      variant="transparent"
      :show="overlay"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <b-row class="m-3">
        <b-col md="3">
          <b-form-input
            v-model="searchInput"
            :placeholder="$t('slider.searchThreeCaracCat')"
            autofocus
            autocomplete="off"
            minlength="3"
            @change="searching()"
          />
        </b-col>
        <b-col md="1">
          <b-button
            variant="info"
            @click="searching()"
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-col>
        <b-col md="2">
          <b-form-checkbox
            v-model="searchById"
            switch
          >
            {{ $t('activeSearchById') }}
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="3"
          class="d-flex justify-content-center"
        >
          <b-pagination
            v-if="rows > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            size="md"
            class=""
            @input="allCategoryShop"
          />
        </b-col>
        <b-col cols="3">
          <b-button
            variant="primary"
            @click="confirmSelection"
          >
            {{
              $t("common.elCaProd")
            }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="dataArray.length == 0 && searchInput.length != 0"
          class="mb-2 ml-5"
        >
          <h5>{{ $t('subscriptions.notFound') }}</h5>
        </b-col>
        <b-col
          v-else-if="dataArray.length > 0 && searchInput.length != 0"
          class="mb-2 ml-5 text-left"
        >
          <h5>{{ $t("common.selCatList") }}</h5>
        </b-col>
      </b-row>
      <b-row style="height:621px">
        <b-col
          style="height:603px"
          class="scroll-list list-group list-group-flush "
        >
          <b-table
            v-if="dataArray.length != 0"
            striped
            hover
            outlined
            :items="dataArray"
            responsive
            :fields="columns"
            :selectable="true"
            class="text-left"
            select-mode="single"
            @row-selected="onRowSelected"
          >
            <!-- image -->
            <template #cell(image)="data">
              <div class="">
                <b-img
                  :src="buildImageUrlBucket(data)"
                  class="tableImage"
                  @error="errorImg"
                />
              </div>
            </template>
            <template #cell(name)="data">
              <div class="">
                {{ data.item.node.name }}
              </div>
            </template>
            <template #cell(id)="data">
              <div class="">
                <strong>
                  <b-link
                    style="color:white; text-decoration: underline;"
                    class="cursor-pointer"
                    :href="'/shop/category-shop/' + data.item.node.id"
                    target="_blank"
                  >
                    {{ data.item.node.id }}
                  </b-link>
                </strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import {
  BButton,
  BTable,
  BImg,
  BOverlay,
  BPagination,
  BCol,
  BRow,
  BFormInput,
  BFormCheckbox,
  BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { getUserData } from '@/auth/utils'
import { messageError } from '@/store/functions'

const fileError = require('@/assets/images/backend/file-error.png')
const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BCol,
    BRow,
    BTable,
    BImg,
    BOverlay,
    BFormInput,
    BPagination,
    BButton,
    BFormCheckbox,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    isFinal: { type: Boolean, default: true }, // ¿Categoría final?
    idNot: { type: String, default: null }, /// /No muestra la categoría con este id
    columns: Array,
  },
  data() {
    return {
      dataArray: [],
      searchInput: '',
      rows: null,
      rowSelected: null,
      overlay: false,
      perPage: 10,
      userData: getUserData(),
      headers: {},
      currentPage: 1,
      searchById: false,
    }
  },
  watch: {
    searchById() {
      this.allCategoryShop()
    },
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
    // eslint-disable-next-line no-empty
    } catch (error) {}

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.allCategoryShop()
  },

  methods: {
    allCategoryShop() {
      const { headers } = this
      this.overlay = true
      const userData = getUserData()
      axios
        .post('', {
          query: `
            query{  
              allCategoryShop(
              first:${this.perPage},
              offset:${this.perPage * (this.currentPage - 1)},
              client:"${userData.profile.client.id}",
              ${!this.searchById ? 'name' : 'id'}:"${this.searchInput}",      
              isFinal:${this.isFinal},
              ${this.idNot ? `idNot:"${this.idNot}"` : ''}
              ){         
              totalCount
              edgeCount
              edges{
                node{
                  id
                  name
                  isFinal
                  type
                  imageUrl
                  image
                  childCategories {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          this.dataArray = res.data.data.allCategoryShop.edges
          this.rows = res.data.data.allCategoryShop.totalCount
          this.overlay = false
        }).catch(error => {
          console.log(error)
        })
    },
    errorImg(e) {
      e.target.src = fileError
    },
    buildImageUrlBucket(data) {
      if (data.item.node.imageUrl) {
        return data.item.node.imageUrl
      }
      return noCover
    },
    onRowSelected(item) {
      this.rowSelected = item.length ? item[0] : null
    },
    confirmSelection() {
      this.$emit('confirm-selection', this.rowSelected)
    },
    searching() {
      if (this.searchInput.length >= 3) {
        this.currentPage = 1
        this.allCategoryShop()
      }
      if (this.searchInput.length < 3) {
        this.dataArray = []
      }
      if (this.searchInput.length === 0) {
        this.allCategoryShop()
      }
    },
  },
}
</script>

<style>
.tableImage {
  margin-right: 2rem;
  border-radius: 10px;
  width: 8rem;
}

.tableHeight {
  height: 700px;
}
</style>
